import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import './Home.css';

class Home extends Component {

  render() {
    return (
      <div>
        <MetaTags>
          <title>clr3 - Justin Cheng</title>
        </MetaTags>
        <div className="cover_image">
          <img src="/beginning.jpg" alt="Enoura Observatory (2019)" />
        </div>
        <p>
          Justin Cheng studies networks and communities.
          He likes chocolate, and is currently a data scientist at Discord.
          He holds a PhD in Computer Science from Stanford University.
        </p>
      </div>
    );
  }
}

export default Home;
