import React, { useState, useEffect } from "react";
import {
  Link,
  useParams
} from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import ReactMarkdown from 'react-markdown'
import './Pause.css';

function PausePost(props) {
  const main_image_url = "../pauses_images/" + props.pause.image;
  const date_posted = props.pause.date_posted ? new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(new Date(props.pause.date_posted)) : "";
  return (
    <div className="pause_post">
      <div className="pause_header">
        {props.pause.title}
      </div>
      <div className="pause_subtitle">
        {date_posted}
      </div>
      <div className="pause_image" style={{
        backgroundImage: "url(" + main_image_url + ")"
      }} onClick={() => props.showImage(main_image_url)}>
      </div>
      <ReactMarkdown>
        {props.pause.content}
      </ReactMarkdown>
      {/* {<div dangerouslySetInnerHTML={{ __html: props.pause.content }} />} */}
      <Link to="/pauses">
        <div className="pause_logo">
          <div className="pause_logo_1"></div>
          <div className="pause_logo_1"></div>
        </div>
      </Link>
    </div>
  );
}

function Pause(props) {
  const [showLightBox, setShowLightBox] = useState(false);
  const [imageToShow, setImageToShow] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let { pauseId } = useParams();

  const showImage = imageUrl => {
    setImageToShow(imageUrl);
    setShowLightBox(true);
  };

  const hideImage = () => {
    setShowLightBox(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch('/api/pauses/' + pauseId)
      .then(res => res.json())
      .then(pause => {
        setIsLoading(false);
        props.reloadPause(pause);
      });
  }, []);

  return (
    <div className="pause_wrapper">
      { props.pause ?
        <MetaTags>
          <title>clr3 - {props.pause.title}</title>
          <meta name="description" content={props.pause.description} />
          <meta property="fb:app_id" content="2524518121186957" />
          <meta property="og:site_name" content="clr3" />
          <meta property="og:title" content={props.pause.title} />
          <meta property="og:description" content={props.pause.description} />
          <meta property="og:image" content={"https://www.clr3.com/pauses_images/" + props.pause.image} />
          <meta property="og:url" content={"https://www.clr3.com/pause/" + props.pause.slug} />
          <meta property="og:type" content="article" />
          <meta property="og:locale" content="en_US" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:site" content="@jcccf" />
          <meta property="twitter:title" content={props.pause.title} />
          <meta property="twitter:description" content={props.pause.description} />
          <meta property="twitter:image" content={"https://www.clr3.com/pauses_images/" + props.pause.image} />
        </MetaTags>
        : 'Can\'t pause here!'}
      { isLoading &&
        <div className="loading pause_loading">
          <div className="loading_1"></div>
          <div className="loading_2"></div>
          <div className="loading_3"></div>
        </div>
      }
      { showLightBox ?
        <div id="lightbox" onClick={() => hideImage()}>
          <img id="lightbox-img" src={imageToShow} alt="Larger version" />
        </div>
        : ''}
      { props.pause ?
        <PausePost
          pause={props.pause}
          showImage={showImage}
        />
        : ''}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    pause: state.pause,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    reloadPause: pause => dispatch({
      type: 'RELOAD_PAUSE',
      payload: pause
    }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Pause);
