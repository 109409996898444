import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

class Contact extends Component {

  render() {
    return (
      <div>
        <MetaTags>
          <title>clr3 - Contact</title>
        </MetaTags>
        <div className="cover_image">
          <img src="/end.jpg" alt="Enoura Observatory (2019)" />
        </div>
        <p>
          The best way to get in touch is via e-mail at justin at cheng dot sg.
          I'm sometimes on <a href="http://www.twitter.com/jcccf">Twitter</a>;
          here's my Facebook Research <a href="https://research.fb.com/people/cheng-justin/">page</a>.
        </p>
      </div>
    );
  }
}

export default Contact;
