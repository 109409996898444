import React, { Component } from "react";
import { connect } from 'react-redux';
import Publication from './Publication';
import MetaTags from 'react-meta-tags';
import './Publications.css';

function PublicationsCategoryPicker(props) {
  return (
    <div className="sortOptions">
      <select value={props.category} onChange={
        (event) => props.changeHandler(event.target.value)
      }>
        <option value="">All</option>
        <option value="social">Social networks</option>
        <option value="wellbeing">Well-being</option>
        <option value="antisocial">Anti-social behavior</option>
        <option value="networks">Network science</option>
        <option value="crowdsourcing">Crowdsourcing</option>
      </select>
      {/*
        <button onClick={this.toggleSort}>
          {this.state.currentOrder == 1 ? 'Year ↓' : 'Year ↑'}
        </button>
      */}
    </div>
  );
}

function PublicationsList(props) {
  return (
    <div className="publications_list">
      {props.publications.map(item =>
        <Publication
          key={item._id}
          title={item.title}
          conference={item.conference}
          year={item.year}
          abstract={item.abstract}
          pdf={item.pdf}
          slides={item.slides}
          press={item.press}
          award={item.award}
        />
      )}
    </div>
  );
}

class Publications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // publications: [],
      isLoading: false,
      currentOrder: 1,
      category: ''
    }

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    // this.toggleSort = this.toggleSort.bind(this);
  }

  handleCategoryChange(category) {
    this.setState({
      isLoading: true,
      category: category
    });
    fetch('/api/publications/' + category)
      .then(res => res.json())
      .then(publications => {
        this.setState({
          // publications,
          isLoading: false
        });
        // console.log("API", publications.length);
        this.props.reloadPublications(publications);
      });
  }

  // toggleSort() {
  //   this.setState({ isLoading: true });
  //   fetch('/api/publications/' + this.state.currentOrder)
  //     .then(res => res.json())
  //     .then(publications => this.setState({
  //       publications,
  //       isLoading: false,
  //       currentOrder: this.state.currentOrder * -1
  //     }));
  // }

  componentDidMount() {
    this.handleCategoryChange('');
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>clr3 - Publications</title>
        </MetaTags>
        <div className="publications_header">
        <PublicationsCategoryPicker
          category={this.state.category}
          changeHandler={this.handleCategoryChange}
        />
        {this.state.isLoading &&
          <div className="loading publications_loading">
            <div className="loading_1"></div>
            <div className="loading_2"></div>
            <div className="loading_3"></div>
          </div>
        }
        </div>
        <PublicationsList
          publications={this.props.publications}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log("STATE", state.length);
  return {
    publications: state.publications
  }
};

const mapDispatchToProps = dispatch => {
  return {
    reloadPublications: publications => dispatch({
      type: 'RELOAD_PUBLICATIONS',
      payload: publications
    }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Publications);
