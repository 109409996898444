import React from 'react';
import {
  Route,
  NavLink
} from 'react-router-dom';
import './App.css';
import Contact from "./Contact";
import Home from "./Home";
import Publications from "./Publications";
import Pauses from "./Pauses";
import Pause from "./Pause";
import Plainly from "./Plainly";

function App() {

  return (
    <div className="App">
      <div className="header">
        <div className="header_title">
          <NavLink exact to="/"><img src="/logo.svg" alt="clr3" height="56" /></NavLink>
        </div>
        <ul className="header_nav">
          <li><NavLink to="/pauses" isActive={(match, location) => {
            return location.pathname.match(/\/(pauses|pause)/g) !== null;
          }}>Pauses</NavLink></li>
          <li><NavLink to="/publications">Publications</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li>
        </ul>
      </div>
      <div className="content">
        <Route exact path="/" component={Home} />
        <Route path="/pauses" component={Pauses} />
        <Route path="/publications" component={Publications} />
        <Route path="/contact" component={Contact} />
        <Route path="/pause/:pauseId" component={Pause} />
        <Route path="/plainly" component={Plainly} />
      </div>
    </div>
  );
}

export default App;
