import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

class Plainly extends Component {

  render() {
    return (
      <div>
        <MetaTags>
          <title>clr3 - Plainly</title>
        </MetaTags>
        <p>Plainly is a super-simple web browser for browsing your favorite web sites full-screen. No address bars, no tabs, no status bars – just a small button at the bottom of your screen.</p>

        <p>
          <b>Here are some common questions you might have. If none of these answers are helpful, contact me and I'll try to help!</b>
        </p>

        <p>
          <b>What's your privacy policy? </b>
          No data is collected about you whatsoever.
        </p>

        <p>
          <b>How do I star a page? How do I access a page that I starred? </b>
          To star a page, tap the Plainly button at the bottom of the screen, then tap the star next to the page URL.
          To access a page that you've starred, swipe up on the Plainly button.
        </p>

        <p>
          <b>How do I hide the Plainly button? </b>
          To hide the Plainly button, tap and hold with two fingers to hide the Plainly button on any web page.
          To show the Plainly button again, use the same action: tap and hold with two fingers on any web page.
        </p>

        <p>
          <b>How do I clear history? </b>
          Tap on the Plainly button at the bottom of the screen, tap on the ellipses (...), and then tap "Reset tab".
          This will also clear your history and reset your starred page to the default.
        </p>

        <p>
          <b>How do I add a passcode? </b>
          Tap on the Plainly button at the bottom of the screen, tap on the ellipsis (...), and then tap "Enable passcode".
          The passcode screen will appear whenever you open the app, or after switching apps.
          To disable the passcode, tap the Plainly button at the bottom of the screen, tap on the ellipsis (...), and then tap "Disable passcode".
          If you forget your passcode, you will have to reinstall the app to regain access.
        </p>

        <p>
          <b>Why does a certain website not load? </b>
          A fair number of web sites do not work well on mobile devices.
          In many cases, (1) tapping on the Plainly button, then tapping on the ellipsis (...), and then tapping "Restart tab",
          or (2) <a href="https://support.apple.com/en-us/HT201330">restarting Plainly altogether</a> may fix the issue.
        </p>

        <p>
          Plainly uses the same browser engine as Mobile Safari, so if a website doesn't work in Mobile Safari, chances are that it won't work in Plainly either.
          But if a website loads fine in Mobile Safari, please contact me and I'll try to take a look.
        </p>

        <p>
          <b>Why does the back button not work properly? </b>
          Some websites manipulate your browser history, making it difficult for browsers to provide this functionality consistently.
          We're looking into ways to resolve this, but can't promise anything at the moment!
        </p>

        <p>
          <b>Why does my browser history not persist when I open the app? </b>
          At present, Plainly only remembers the last URL you visited, not your complete browsing history.
        </p>

      </div>
    );
  }
}

export default Plainly;
