import React, { useState, useEffect } from "react";
import {
  Link
} from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import './Pauses.css';

function PausesListItem(props) {
  const main_image_url = "../pauses_images/" + props.pause.preview_image;
  const date_posted = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(new Date(props.pause.date_posted));
  return (
    <div className="pause_list_item">
      <Link to={{
        pathname: "/pause/" + props.pause.slug
      }}>
        <div className="pause_list_item_image" style={{
          backgroundImage: "url(" + main_image_url + ")"
        }}>
        </div>
        <div className="pause_list_item_title">
          {props.pause.title}
        </div>
        <div className="pause_list_item_date">
          {date_posted}
        </div>
      </Link>
    </div>
  );
}

function PausesList(props) {
  return (
    <div className="pauses_list">
      {props.pauses.map(item =>
        <PausesListItem
          key={item.id}
          pause={item}
        />
      )}
    </div>
  );
}

function Pauses(props) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch('/api/pauses/')
      .then(res => res.json())
      .then(pauses => {
        setIsLoading(false);
        // console.log("API", publications.length);
        props.reloadPauses(pauses);
      });
  }, []);

  return (
    <div className="pauses_wrapper">
      <MetaTags>
        <title>clr3 - Pauses</title>
        <link rel="alternate" type="application/atom+xml" href="/pauses/feed/atom" />
	      <link rel="alternate" type="application/json" href="/pauses/feed/json" />
      </MetaTags>
      { isLoading &&
        <div className="loading pauses_loading">
          <div className="loading_1"></div>
          <div className="loading_2"></div>
          <div className="loading_3"></div>
        </div>
      }
      <PausesList
        pauses={props.pauses}
      />
    </div>
  );
}

const mapStateToProps = state => {
  // console.log("STATE", state.length);
  return {
    pauses: state.pauses,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    reloadPauses: pauses => dispatch({
      type: 'RELOAD_PAUSES',
      payload: pauses
    }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Pauses);
