import React, { Component } from 'react';

function PublicationYear(props) {
  if (props.conference || props.year || props.award) {
    return (
      <div className="publication_year">
        {props.conference &&
          <span>{props.conference}{' '}</span>
        }
        {props.year}
        {props.award &&
          <span>{' '}{props.award}</span>
        }
      </div>
    );
  }
  return null;
}

function PublicationLinks(props) {
  if (props.pdf || props.slides) {
    return (
      <div className="publication_link">
        {props.pdf &&
          <a href={props.pdf}>PDF</a>
        }
        {props.slides &&
          <a href={props.slides}>Slides</a>
        }
      </div>
    );
  }
  return null;
}

function PublicationPressLink(props) {
  return (
    <span>
      <a href={props.url}>{props.title}</a>
      {props.isLast ? "" : ", "}
    </span>
  )
}

function PublicationPressLinks(props) {
  return (
    <div className="publication_press_links">
      Press:&nbsp;
      {props.press.map((item, i, arr) =>
        typeof item == "string" ?
          <PublicationPressLink
            title="Untitled"
            url={item}
            isLast={arr.length - 1 === i}
            key={item.url}
          />
        :
          <PublicationPressLink
            title={item.title}
            url={item.url}
            isLast={arr.length - 1 === i}
            key={item.url}
          />
      )}
    </div>
  );
}

class Publication extends Component {

  render() {
    return (
      <div className="publication">
        <div className="publication_title">
          {this.props.title}
        </div>
        <PublicationYear
          conference={this.props.conference}
          year={this.props.year}
          award={this.props.award}
        />
        <PublicationLinks
          pdf={this.props.pdf}
          slides={this.props.slides}
        />
        <p>
          {this.props.abstract}
        </p>
        {this.props.press &&
          <PublicationPressLinks
            press={this.props.press}
          />
        }
      </div>
    );
  }
}

export default Publication;
