import { combineReducers } from 'redux'
import publications from './publications'
import pauses from './pauses'
import pause from './pause'

export default combineReducers({
  publications,
  pauses,
  pause,
})
